import React, {useState, useRef, useEffect} from "react";

import AreaCard from './graphs/AreaCard';

import { FileSearchOutlined  } from '@ant-design/icons';
import { Table, Row, Col, Button, Typography, Tooltip, Checkbox } from 'antd';
import GenerateCsvButton from "../components/GenerateCsvButton";
const { Text } = Typography;

const initialLocation = ['mail', 'sharepoint', 'onedrive']

export default function Overview(props) {

    // Track graph filters
    const [graphFilter, setGraphFilter] = useState(null)
    const [subGraphFilter, setSubGraphFilter] = useState([])
    const [location, setLocation] = useState(initialLocation)

    // Unfortunately need refs for comparison with the above...
    const gfRef = useRef()
    const sgfRef = useRef()
    const locRef = useRef()

    useEffect(() => {
        locRef.current = initialLocation
        gfRef.current = null
        sgfRef.current = []
    }, [])
    
    // TODO - filter controls need to be moved to the graphs, there is more behavior to be handled than just selecting

    const clickGraph = (filter) => {
        console.log(filter, locRef.current, gfRef.current, sgfRef.current)
        // console.log(filter, gfRef.current)
        setSubGraphFilter([])
        sgfRef.current = []
        if (filter == null) {  // filter == null => resetting overview data
            setGraphFilter(null)
            setSubGraphFilter([])
            gfRef.current = null
            sgfRef.current = []
            setLocation(initialLocation)
            locRef.current = initialLocation
            props.fetchData() // fetch live data
        } else if (filter === gfRef.current) { // clicked on the same chosen slice => no filters
            setGraphFilter(null)
            gfRef.current = null
            // Enable to get live data
            // props.fetchData()
            if (locRef.current === initialLocation){
                // For optimization purposes - use already calculated data
                props.setData(props.initData.data)
                props.setGraphData(props.initData.graph_data)
                props.setSubGraphData(props.initData.sub_graph_data)
            } else {
                props.fetchOverviewFiltered(locRef.current)
            }
            // // TODO change resetting into data for proper checkbox selection
            // setLocation(initialLocation)
            // locRef.current = initialLocation
        } else {
            // console.log("Setting graph filter...")
            setGraphFilter(filter)
            gfRef.current = filter
            // reset subgraph chosen pie...
            props.fetchSubGraphData(filter, locRef.current)
            // props.setSubGraphData(data_dict.sub_graph_data)

        }
    }

    // TODO - include multiselect logic
    const clickSubGraph = (filter) => {
        console.log(filter, locRef.current, gfRef.current, sgfRef.current)
        if (sgfRef.current.length === 1 && filter === sgfRef.current[0] || filter == null) {
            setSubGraphFilter([])
            sgfRef.current = []
            // reset data
            props.setData(props.initData.data)
            setLocation(initialLocation)
            locRef.current = initialLocation
        } else if (sgfRef.current.length === 0){
            setSubGraphFilter([filter])
            sgfRef.current = [filter]
            props.fetchDataFiltered([filter], locRef.current)
        } else {
            // console.log("Setting subgraph filter...")
            let newFilter = []
            if (sgfRef.current.includes(filter)) {
                newFilter = sgfRef.current
                newFilter = newFilter.filter(f => f !== filter)
            } else {
                newFilter = sgfRef.current
                newFilter.push(filter)
            }
            setSubGraphFilter(newFilter)
            sgfRef.current = newFilter
            if (newFilter.length === 0) { // TODO why? Shouldn't it fetch for current graph filter?
                if (gfRef.current.length === 0) { // no graph filters -> reset to checkboxes only TODO track checkboxes...
                    props.setData(props.initData.data)
                    setLocation(initialLocation)
                    locRef.current = initialLocation
                } else {
                    props.fetchSubGraphData(gfRef.current, locRef.current)
                }
            } else {
                props.fetchDataFiltered(newFilter, locRef.current)
            }
        }
    }

    let goToReportButton = (user, period, title) => {
        return <Tooltip mouseLeaveDelay={0} title={title} placement="top">
            <Button type="text" onClick={() => props.clickUserPeriodCategory(user, period, graphFilter, subGraphFilter, location)}>
                <FileSearchOutlined />
            </Button>
        </Tooltip>
    }

    const clickCheckbox = (e, checkboxValue) => {
        var newLocations = []
        if (e.target.checked) newLocations = [...locRef.current, checkboxValue] 
        else newLocations = locRef.current.filter(val => val !== checkboxValue)
        // if (newLocations.length == 0) newLocations = initialLocation
        setLocation(newLocations)
        locRef.current = newLocations
        setGraphFilter(null)
        gfRef.current = null
        setSubGraphFilter([])
        sgfRef.current = []
        // Handle click
        props.fetchOverviewFiltered(newLocations)
    }

    const locationCheckboxes = 
        <React.Fragment>
            <Row>
                <Col span={24}>
                    <Checkbox 
                        checked={location.includes('mail')}
                        onChange={e => clickCheckbox(e, 'mail')} 
                    >
                        <Text strong>Outlook</Text>
                    </Checkbox>
                </Col>
                <Col span={24} style={{marginTop: 4}}>
                    <Checkbox 
                        checked={location.includes('sharepoint')}
                        onChange={e => clickCheckbox(e, 'sharepoint')}
                    >
                        <Text strong> Sharepoint </Text>
                    </Checkbox>
                </Col>
                <Col span={24} style={{marginTop: 4}}>
                    <Checkbox 
                        checked={location.includes('onedrive')}
                        onChange={e => clickCheckbox(e, 'onedrive')}
                    >
                        <Text strong> OneDrive </Text>
                    </Checkbox>
                </Col>
            </Row>
        </React.Fragment>

    const columns = [
        { title: <Text>Account</Text>, dataIndex: 'user', key: 'user', width: 200,
            sorter: (a, b) => a.user > b.user,  
            render: (val, record) => record.user === "All"
                ? null
                : <React.Fragment><Text>{val}</Text></React.Fragment>
        },
        { title: 'Total cases', dataIndex: 'cases_total', key: 'cases_total', 
            sorter: (a, b) => a.cases_total > b.cases_total,  
            render: (val, record) => record.user === "All"
                ? null
                : <React.Fragment><Text>{val}</Text> {goToReportButton(record.user, 'all', "Click to view user data")}</React.Fragment>
        },
        { title: 'Cases this week', dataIndex: 'cases_week', key: 'cases_week', 
            sorter: (a, b) => a.cases_week > b.cases_week,  
            render: (val, record) => record.user === "All"
                ? null
                : <React.Fragment><Text>{val}</Text> {goToReportButton(record.user, 'week', "Click to view user data for this week")}</React.Fragment>
 
        },
        { title: 'Cases last 6 months', dataIndex: 'cases_six_months', key: 'cases_six_months', 
            sorter: (a, b) => a.cases_six_months > b.cases_six_months,  
            render: (val, record) => record.user === "All"
                ? null
                : <React.Fragment><Text>{val}</Text> {goToReportButton(record.user, 'six_months', "Click to view user data for this period")}</React.Fragment>  
        },
        { title: 'Cases older than 6 months', dataIndex: 'cases_more_than_six_months', key: 'cases_more_than_six_months', 
            sorter: (a, b) => a.cases_more_than_six_months > b.cases_more_than_six_months, 
            render: (val, record) => record.user === "All"
                ? null
                : <React.Fragment><Text>{val}</Text> {goToReportButton(record.user, 'more_than_six_months', "Click to view user data for this period")}</React.Fragment>  
        },
        {
          title: 'Actions', key: 'actions', width: '150',
          render: (val, record) =>
                <div>
                    <GenerateCsvButton generateCsv={() => props.generateCsv(record.user, false, graphFilter, subGraphFilter, location)} />
                </div>
        },
    ];

    const { loading, loadingSub, loadingData, data, graphData, subGraphData } = props

    const areaCardHeight = 400
    const overviewHeight = props.intFrameHeight - 68
    const tableHeight = overviewHeight - areaCardHeight - 112 > 259 ? overviewHeight - areaCardHeight - 112 : 147

    return (
        <React.Fragment>
            <Row gutter={8}>
                <Col xl={12} lg={24} style={{marginBottom: 8}}>
                    <AreaCard data={graphData} clickGraph={clickGraph}
                        checkboxes={locationCheckboxes}
                        loading={loading}
                        loadingData={loadingData}
                        showResetButton={true}
                        cardTitle={"GDPR cases per category"}
                        barLegendLayout="horizontal" barLegendPosition="bottom"
                        style={{height: areaCardHeight}}
                        bodyStyle={{height: areaCardHeight - 80, padding: 8}}
                        multiSelect={false}
                    />
                </Col>
                <Col xl={12} lg={24} style={{marginBottom: 8}}>  
                    <AreaCard data={subGraphData} clickGraph={clickSubGraph}
                        loading={loading || loadingSub}
                        loadingData={loadingData}
                        cardTitle={"GDPR cases per domain"}
                        barLegendLayout="horizontal" barLegendPosition="bottom"
                        style={{height: areaCardHeight}}
                        bodyStyle={{height: areaCardHeight - 80, padding: 8}}
                        multiSelect={true}
                    />
                </Col>
            </Row>
            <Table
                loading={loading || loadingData}
                columns={columns}
                dataSource={data.filter(row => row.user !== 'All')}
                style={{maxHeight: tableHeight, height: tableHeight, minHeight: tableHeight}}
                scroll={{y: tableHeight}}
                size="small"
                showHeader={true}
                pagination={false}
                summary={() => {
                    let all_record
                    data.forEach( record => {
                        if (record.user === "All") {
                            all_record = record
                        }
                    })
                    if (!!all_record)
                        return (
                        <Table.Summary fixed>
                            <Table.Summary.Row style={{borderTop: 'black'}}>
                            <Table.Summary.Cell index={0}>
                                {/* <Tooltip mouseLeaveDelay={0} title="Click to view all data" placement="top">
                                    <Text strong onClick={() => props.clickUserPeriod(null, 'all')}>{all_record.user}</Text>
                                </Tooltip> */}
                                <Text strong>{all_record.user}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                {/* <Tooltip mouseLeaveDelay={0} title="Click to view all data" placement="top">
                                    <Text strong onClick={() => props.clickUserPeriod(null, 'all')}>{all_record.cases_total}</Text>
                                </Tooltip> */}
                                <Text strong>{all_record.cases_total}</Text>
                                {goToReportButton(null, 'all', "Click to view all data")}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                {/* <Tooltip mouseLeaveDelay={0} title="Click to view all data for this week" placement="top">
                                    <Text strong onClick={() => props.clickUserPeriod(null, 'week')}>{all_record.cases_week}</Text>
                                </Tooltip> */}
                                <Text strong>{all_record.cases_week}</Text>
                                {goToReportButton(null, 'week', "Click to view all data for this week")}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                {/* <Tooltip mouseLeaveDelay={0} title="Click to view all data for period" placement="top">
                                    <Text strong onClick={() => props.clickUserPeriod(null, 'six_months')}>{all_record.cases_six_months}</Text>
                                </Tooltip> */}
                                <Text strong>{all_record.cases_six_months}</Text>
                                {goToReportButton(null, 'six_months', "Click to view all data for this period")}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                {/* <Tooltip mouseLeaveDelay={0} title="Click to view all data for period" placement="top">
                                    <Text strong onClick={() => props.clickUserPeriod(null, 'more_than_six_months')}>{all_record.cases_more_than_six_months}</Text>
                                </Tooltip> */}
                                <Text strong>{all_record.cases_more_than_six_months}</Text>
                                {goToReportButton(null, 'more_than_six_months', "Click to view all data for this period")}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                                <GenerateCsvButton generateCsv={() => props.generateCsv(null, true, graphFilter, subGraphFilter, location)} />
                            </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    ) 
                }}
            />
        </React.Fragment>
    );
}