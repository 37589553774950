import React, { useState, useEffect, useMemo } from 'react';
import { Button, Transfer, Modal, Row, Col, Tooltip, Radio, Typography, Space } from 'antd';
import { SettingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SelectAccountsTransfer = (props) => {

    const [transferOpen, setTransferOpen] = useState(false);
    
    const [valueRadio, setValueRadio] = useState('scan');

    const [targetKeys1, setTargetKeys1] = useState([]);
    const [selectedKeys1, setSelectedKeys1] = useState([]);

    const [targetKeys2, setTargetKeys2] = useState([]);
    const [selectedKeys2, setSelectedKeys2] = useState([]);

    useEffect(() => {
        if (targetKeys1 !== props.chosen1) setTargetKeys1(props.chosen1)
        if (targetKeys2 !== props.chosen2) setTargetKeys2(props.chosen2)
        if (valueRadio !== props.selectSkip) setValueRadio(props.selectSkip)
    }, [props.chosen1, props.chosen2, props.selectSkip])

    const onChange1 = (nexttargetKeys, direction, moveKeys) => {
        setTargetKeys1(nexttargetKeys);
    };

    const onSelectChange1 = (sourceselectedKeys, targetselectedKeys) => {
        setSelectedKeys1([...sourceselectedKeys, ...targetselectedKeys]);
    };

    const onChange2 = (nexttargetKeys, direction, moveKeys) => {
        setTargetKeys2(nexttargetKeys);
    };

    const onSelectChange2 = (sourceselectedKeys, targetselectedKeys) => {
        setSelectedKeys2([...sourceselectedKeys, ...targetselectedKeys]);
    };

    const handleOk = () => {
        props.onAccept1(targetKeys1)
        props.onAccept2(targetKeys2)
        props.setSelectSkip(valueRadio)
        setTransferOpen(false)
    }

    const handleCancel = () => {
        setTargetKeys1(props.chosen1)
        setTargetKeys2(props.chosen2)
        setTransferOpen(false)
    }

    // radio controls
    const optionsRadio = [
        {
            label: <Text>Scan selected users <Text strong>ONLY</Text></Text>,
            value: 'scan',
        },
        {
            label: 'Skip selected users',
            value: 'skip',
        },
    ];

    const onChangeRadio = ({ target: { value } }) => {
        setValueRadio(value);
    };

    const data1 = useMemo(() => props.lookupData1.map((value, i) => ({
        key: value,
        title: value,
        description: "",
    })), [props.lookupData1])

    const data2 = useMemo(() => props.lookupData2.map((value, i) => ({
        key: value,
        title: value,
        description: "",
    })), [props.lookupData2])

    return (
        <>
            <Tooltip mouseLeaveDelay={0} title="Edit" placement="top">
                <Button 
                    size="small" 
                    icon={<SettingOutlined />} 
                    onClick={() => setTransferOpen(true)}
                    style={{marginRight: 4}}
                    disabled={!!props.disabled}
                >
                </Button>
            </Tooltip>
            <Modal
                title={
                    <Space size={8}>
                        <span style={{display: "inline-block", marginRight: 8}}>
                            {"Select or exclude " + props.modalTitle + " from scanning"}
                        </span>
                        {/* <span style={{display: "inline-block", marginRight: 8}}>
                            <Radio.Group options={optionsRadio} onChange={onChangeRadio} value={valueRadio} />
                        </span> */}
                    </Space>
                }
                open={transferOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                footer={[
                    <Row justify="space-between">
                        <Col>
                            <Radio.Group options={optionsRadio} onChange={onChangeRadio} value={valueRadio} />
                        </Col>
                        <Col>
                            <Button key="back" onClick={handleCancel}>
                                Return
                            </Button>
                            <Button key="submit" type="primary" onClick={handleOk}>
                                Apply
                            </Button>
                        </Col>
                    </Row>
                ]}
                width={1040}
                centered
                style={{}}
            >
                <Row>
                    <Col xl={12} md={24} style={{marginBottom: 4}}>
                        <Transfer
                            dataSource={data1}
                            titles={['Available accounts', 'Scanned accounts']}
                            disabled={valueRadio === 'skip'}
                            showSearch
                            targetKeys={targetKeys1}
                            selectedKeys={selectedKeys1}
                            onChange={onChange1}
                            onSelectChange={onSelectChange1}
                            render={(item) => item.title}
                            listStyle={{
                                width: 220,
                                height: 400,
                            }}
                        />
                    </Col>
                    <Col xl={12} md={24}>
                        <Transfer
                            dataSource={data2}
                            titles={['Scanned accounts', 'Skipped accounts']}
                            disabled={valueRadio === 'scan'}
                            showSearch
                            targetKeys={targetKeys2}
                            selectedKeys={selectedKeys2}
                            onChange={onChange2}
                            onSelectChange={onSelectChange2}
                            render={(item) => item.title}
                            listStyle={{
                                width: 220,
                                height: 400,
                            }}
                        />
                    </Col>
                </Row>
            </Modal>
        
        </>
    );
};

export default SelectAccountsTransfer;