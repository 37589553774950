import React, {useState, useContext} from 'react';

import {UserContext} from './user-context';

import { Button, Dropdown, Menu } from 'antd';


import {UserOutlined, LogoutOutlined, FieldTimeOutlined, UserSwitchOutlined, FolderViewOutlined} from '@ant-design/icons';

export default function UserLogoutButton(props) {
    
    let { clientPrincipal, role } = useContext(UserContext);
    if (window.o365_config.USE_DEVELOPMENT_BUILD) {
        clientPrincipal = {"userDetails": "test", "userRoles": ["allowed", "master"]}
        role = "admin"
    }
    // const clientPrincipal = {"userDetails": "test", "userRoles": ["allowed", "master"]}
    // const [role, setRole] = useState("admin")
    const [loggingOut, setLoggingOut] = useState(false)
    const [menuVisible, setMenuVisible] = useState(false)
    
    const handleVisibleChange = flag => {
        setMenuVisible(flag)
    };

    const menu = (
      <Menu onClick={() => {}} mode="vertical">
        <Menu.Item key="account" icon={<UserOutlined />}>
            <span >Account: {clientPrincipal.userDetails}</span>
        </Menu.Item>
        {clientPrincipal.userRoles.includes("master") 
        ? <Menu.SubMenu 
            key="role" icon={<FolderViewOutlined />} 
            title={<span >Role: {role === "admin" ? "Admin" : "User"}</span>}
            onClick={e => {props.changeRole(e.key)}}
        >
            <Menu.Item key="admin" icon={<UserSwitchOutlined />}>
                <span >Admin</span>
            </Menu.Item>
            <Menu.Item key="user" icon={<UserSwitchOutlined />}>
                <span >User</span>
            </Menu.Item>
        </Menu.SubMenu>
        : <Menu.Item key="role" icon={<FolderViewOutlined />}>
            <span >Role: User</span>
        </Menu.Item>   
        }
        <Menu.Item key="scan_date" icon={<FieldTimeOutlined />}>
  		    <span >Last scan: {props.lastScanDate}</span>
  		</Menu.Item>
      </Menu>
    );

    return (
            <Button.Group>
                {/* {props.themeSwitchButton} */}
                <Dropdown overlay={menu} visible={menuVisible} onVisibleChange={handleVisibleChange}>
                    <Button
                        size="large"
                        type="primary" 
                        style={{marginLeft: 4}}
                        icon={<UserOutlined />}>
                    </Button>
                 </Dropdown>
                <Button
                    loading={loggingOut}
                    type="primary" 
                    size="large"
                    href={`/.auth/logout`}
                    icon={<LogoutOutlined />}
                    onClick={() => setLoggingOut(true)}
                >
                    Logout
                </Button>
            </Button.Group>
    );
}
