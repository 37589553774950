import React from 'react';

import { Statistic, Row, Col } from 'antd';
import { MailOutlined, TeamOutlined, PushpinOutlined, AlertOutlined, CloudServerOutlined } from '@ant-design/icons';

const data = [
    {
        "title": "E-mails scanned",
        "key": "mails_scanned",
        "color": '#3498db',
        "icon": <MailOutlined />
    },{
        "title": "E-mails GDPR",
        "key": "mails_gdpr",
        "color": '#b52404',
        "icon": <MailOutlined style={{ fontSize: 20 }}/>
    },{
        "title": "Attachments scanned",
        "key": "attachments_scanned",
        "color": '#3498db',
        "icon": <PushpinOutlined />
    },{
        "title": "Attachments GDPR",
        "key": "attachments_gdpr",
        "color": '#b52404',
        "icon": <PushpinOutlined style={{ fontSize: 20 }}/>,
        // "icon": <Badge count={<AlertFilled style={{ color: '#f5222d' }} />}><PushpinOutlined style={{ fontSize: 20 }}/></Badge>
    },{
    //     "title": "Components scanned",
    //     "key": "components_scanned",
    //     "color": '#3498db',
    //     "icon": <TeamOutlined />
    // },{
    //     "title": "Components GDPR",
    //     "key": "components_gdpr",
    //     "color": '#3498db',
    //     "icon": <Badge count={<ExclamationCircleFilled style={{ color: '#f5222d' }} />}><TeamOutlined style={{ fontSize: 20 }}/></Badge>
    // },{
        "title": "Files scanned",
        "key": "files_scanned",
        "color": '#3498db',
        "icon": <CloudServerOutlined />
    },{
        "title": "Files GDPR",
        "key": "files_gdpr",
        "color": '#b52404',
        "icon": <CloudServerOutlined style={{ fontSize: 20 }}/>
    },{
       "title": "Accounts scanned",
       "key": "users",
       "color": '#3498db',
       "icon": <TeamOutlined />
   },{
        "title": "GDPR cases",
        "key": "violations",
        "color": '#b52404',
        "icon": <AlertOutlined />
    }
]

function propsAreEqual(prevProps, nextProps) {
    return prevProps.statistics === nextProps.statistics
        && prevProps.loading === nextProps.loading;
}

const Statistics = React.memo(function Statistics(props) {
    return (
        <Row gutter={8} justify="space-between">
            {!!props.statistics && data.map(obj => {
                return (
                  <Col key={obj.title}>
                      <Statistic
                        title={obj.title}
                        value={props.statistics[obj.key]}
                        prefix={obj.icon}
                        loading={props.loading}
                        valueStyle={{ color: obj.color, textAlign: "center", display: "block", fontSize: 20 }}
                      />
                  </Col>
                )
            })}
        </Row>
  );
}, propsAreEqual);

export default Statistics;
