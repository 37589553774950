
export const color_dict = {
    "GDPR": '#52c41a', //green
    "GDPR Confidential": '#f7e23e', //yellow
    "GDPR Sensitive": '#eb5934', //red
    "Demographic": "#19943e", //green
    "General": "#1fbf13", //green
    "Work": "#5cd91e", //green
    "Financial": "#f5ea14", // yellow
    "Legal": "#f0c60c", // yellow
    "Affiliation": "#f05e1a", //red
    "Health": "#eb4034", //red
}

