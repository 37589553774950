import React, { useState, useEffect, useMemo } from 'react';
import { Button, Transfer, Modal, Row, Col, Tooltip, Radio, Typography, Space } from 'antd';
import { SettingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SelectAccountsTransfer = (props) => {

    const [transferOpen, setTransferOpen] = useState(false);
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);


    useEffect(() => {
        if (targetKeys !== props.chosen) setTargetKeys(props.chosen)
    }, [props.chosen])

    const onChange = (nexttargetKeys, direction, moveKeys) => {
        setTargetKeys(nexttargetKeys);
    };

    const onSelectChange = (sourceselectedKeys, targetselectedKeys) => {
        setSelectedKeys([...sourceselectedKeys, ...targetselectedKeys]);
    };

    const handleOk = () => {
        props.onAccept(targetKeys)
        setTransferOpen(false)
    }

    const handleCancel = () => {
        setTargetKeys(props.chosen)
        setTransferOpen(false)
    }

    const data = useMemo(() => props.lookupData.map((value, i) => ({
        key: value,
        title: value,
        description: "",
    })), [props.lookupData])

    return (
        <>
            <Tooltip mouseLeaveDelay={0} title="Edit" placement="top">
                <Button 
                    size="small" 
                    icon={<SettingOutlined />} 
                    onClick={() => setTransferOpen(true)}
                    style={{marginRight: 4}}
                    disabled={!!props.disabled}
                >
                </Button>
            </Tooltip>
            <Modal
                title={
                    <Space size={8}>
                        <span style={{display: "inline-block", marginRight: 8}}>
                            {"Select accounts to grant view data consent"}
                        </span>
                    </Space>
                }
                open={transferOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                width={600}
                centered
                style={{}}
            >
                <Row>
                    <Col xl={24} md={24} style={{marginBottom: 4}}>
                        <Transfer
                            dataSource={data}
                            titles={['Available accounts', 'View data consent granted']}
                            showSearch
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            render={(item) => item.title}
                            listStyle={{
                                width: 220,
                                height: 400,
                            }}
                        />
                    </Col>
                </Row>
            </Modal>
        
        </>
    );
};

export default SelectAccountsTransfer;