import React from "react";

export const UserContext = React.createContext({
    clientPrincipal: null,
    route: null,
    role: null,
    setClientPrincipal: () => {},
    setRoute: () => {},
    setRole: () => {},
});
