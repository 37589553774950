import React, { useState, useEffect, useMemo } from "react";

import App from "./App";
import {UserContext} from './user-context';

import { message, Spin, Button, Row, Col } from 'antd';

import { LoginOutlined } from '@ant-design/icons';

export default function Login(){

    const [route, setRoute] = useState(null);
    const [role, setRole] = useState(null);
    const [clientPrincipal, setClientPrincipal] = useState(null);
    const userContextValue = useMemo(
        () => ({ route, clientPrincipal, role, setRoute, setClientPrincipal, setRole }), 
        [route, clientPrincipal]
    );

    // style state - keep track of viewport height
    const intFrameHeight = window.innerHeight - 1;
    
    // const providers = ['twitter', 'github', 'aad'];
    const redirect = window.location.pathname;
    
    useEffect(() => {
        (async () => {
            setClientPrincipal(await getUserInfo());
        })();
    }, []);
  
    async function getUserInfo() {
        setClientPrincipal(null)
        try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            if (clientPrincipal == null){
                return false
                // window.location.assign(`/.auth/login/aad?post_login_redirect_uri=${redirect}`)
            }
            if (!!clientPrincipal.userRoles && !clientPrincipal.userRoles.includes("authenticated")){
                message.info("Not authorized to view content...")
                // window.location.assign(`/.auth/login/aad?post_login_redirect_uri=${redirect}`)
                return false
            }
            if (clientPrincipal.userRoles.includes("master")) {
                setRoute("api/master")
                setRole("admin")
            } else {
                setRoute("api/user")
                setRole("user")
            }
            return clientPrincipal;
        } catch (error) {
            console.error('No profile could be found');
            return false;
        }
    }

    return (
        <UserContext.Provider value={userContextValue}>
        {clientPrincipal === null
            ? <span style={{textAlign: "center", display: "block", paddingTop: 0.3*intFrameHeight}}>
                {<Spin spinning={true} tip="Fetching user..." style={{marginTop: -20}}>
                    {false
                        ? <img src='./images/CompliancePurpleOutlined.png' style={{minWidth:200, minHeight:200}} />
                        : <img src='./images/CompliancePurple.png' style={{minWidth:200, minHeight:200}} />}
                </Spin>}
            </span>
            : clientPrincipal
                ? <App />
                : <Row style={{paddingTop: 0.3*intFrameHeight}}>
                    <Col span={10}></Col>
                    <Col span={4} style={{minWidth: 300}}>
                        <span style={{textAlign: "center", display: "block"}}>
                            <img src='./images/CompliancePurple.png'/>
                        </span>
                        <Button size="large" style={{width: '100%'}} icon={<LoginOutlined />} type="primary" href={`/.auth/login/aad?post_login_redirect_uri=${redirect}`}>Continue to report...</Button>
                    </Col>
                    <Col span={10}></Col>
                </Row>
        }
        </UserContext.Provider>
    );
}