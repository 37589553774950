import React from "react";

// import { useThemeSwitcher } from "react-css-theme-switcher";

import { FileExcelOutlined  } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';


export default function GenerateCsvButton(props) {

    // const { currentTheme } = useThemeSwitcher();
    const currentTheme = "light"

    return (
        <Tooltip mouseLeaveDelay={0} title="Export to CSV" placement="top">
            <Button 
                onClick={props.generateCsv}
                disabled={!!props.disabled}
                style={!!props.disabled ? {} : currentTheme === 'dark'
                    ? { background: '#378233', borderColor: '#378233', color: '#eeeeee' }
                    : { background: '#378233', borderColor: '#378233', color: '#eeeeee' }
                }
            >
                <FileExcelOutlined />
            </Button>
        </Tooltip>
    );
}