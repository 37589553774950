import React, {useEffect, useState, useContext} from 'react';
import MailOutlined from '@ant-design/icons/MailOutlined';
import FlagOutlined from '@ant-design/icons/FlagOutlined';
import axios from 'axios';  
import { Modal, Button, Input, Typography, message, Checkbox, Row, Col, Tooltip, Radio, Space } from 'antd';

import { UserContext } from '../user-context';

const { Text } = Typography;

function TestText(props){

    const [textObject, setTextObject] = useState({})

    useEffect(() => {
        setTextObject(props.textObject)
    }, [props.textObject])

    return (
        <>
            {Object.entries(textObject).map(([i, item], index) => {
                if (item.status !== '' && item.status !== 'non_gdpr_censored') {
                    return (
                        <>
                          <Tooltip title={item.status === 'gdpr' ? "Click to anonymise" : "Click to reset"}>
                          <Text strong onClick={() => props.anonymizeTextObject(i)} onMouseOver={(e) => (e.target.style.color='red', e.target.style.cursor='pointer')} onMouseOut={(e) => (e.target.style.color='black')} >{item.status === 'gdpr' ? item.text : "[ANONYMISED] "}</Text>
                          </Tooltip>
                        </>
                    )
                }
                else {
                    return(
                      <>
                        <Text>{item.status === '' ? item.text : '*'.repeat(item.text.trim().length)}</Text>
                      </>
                    )
                  } 
            })}
        </>
    )
}

export default function ReportButton(props){
    const { route, role, setRole, setRoute } = useContext(UserContext);

    const [visible, setVisible] = useState(false)
    const [comment, setComment] = useState('Entry does not contain any Personal Identifiable Information')
    const [reportText, setReportText] = useState(props.record.violation)
    // const [modifiedText, setModifiedText] = useState("")
    // const [anonymizationActive, setAnonymizationActive] = useState(false)
    const [textObject, setTextObject] = useState({})
    const [radioValue, setRadioValue] = useState(1)

    useEffect(() => {
        let textObj = []
        let tempTextObj = {}
        let textList = props.record.violation.split(/(?=<b>|<\/b>)|(?<=<b>|<\/b>)/g)
        let boldFlag = false
        for (const [index, item] of textList.entries()){
            if (item === '</b>'){
                boldFlag = false
            }
            else if (item === '<b>'){
                boldFlag = true
            }
            else if (boldFlag) {
                tempTextObj[index] = {'text': item, 'status': 'gdpr'}
                boldFlag = false
            } else {
                tempTextObj[index] = {'text': item, 'status': ''}
            }
        }
        for (const [index, item] of Object.entries(tempTextObj)){
            if (item.status === 'gdpr') {
                let tempTextList = item.text.split(' ')
                for (const [i, word] of tempTextList.entries()){
                  if (word !== '') textObj.push({'text': word + " ", 'status': 'gdpr'})
                }
            }
            else {
             textObj.push(item)
            }
        }
        setTextObject(textObj)
        // setModifiedText(props.record.text)
    }, [props.record])

    const toggleModal = (modalToggled) => {
        setVisible(modalToggled)
    };

    const anonymizeText = (text) => {
        let re_bold = new RegExp("<b>.*?<\/b>", "g")
        return text.replace(re_bold, "[" + props.record.area + " ANONYMISED]")
    }

    const toggleAnonymyzeAll = (toggle) => {
        if (toggle) {
            setTextObject(to => {
                const newTo = {}
                Object.keys(to).map(key => newTo[key] = {"text": to[key].text, "status": to[key].status === '' ? to[key].status : 'censored'})
                return newTo
            })
        } else {
            setTextObject(to => {
                const newTo = {}
                Object.keys(to).map(key => newTo[key] = {"text": to[key].text, "status": to[key].status === '' ? to[key].status : 'gdpr'})
                return newTo
            })
        }
    }

    // const toggleAnonymization = () => {
    //     setAnonymizationActive(aa => {
    //         if (aa) setModifiedText(props.record.text)
    //         else setModifiedText(text => anonymizeText(text)) 
    //         return !aa
    //     })
    // }

    const anonymizeTextObject = (i) => {
        if (textObject[i].status === 'gdpr') {
            setTextObject(to => {
                const newTo = {}
                Object.keys(to).map(key => newTo[key] = to[key])
                newTo[i].status = 'censored'
                return newTo
            })
        } else {
            setTextObject(to => {
                const newTo = {}
                Object.keys(to).map(key => newTo[key] = to[key])
                newTo[i].status = 'gdpr'
                return newTo
            })
        }
    }

    const anonymizeNonGdprTextObject = (i) => {
        if (textObject[i].status === '') {
            setTextObject(to => {
                const newTo = {}
                Object.keys(to).map(key => newTo[key] = to[key])
                newTo[i].status = 'non_gdpr_censored'
                return newTo
            })
        } else {
            setTextObject(to => {
                const newTo = {}
                Object.keys(to).map(key => newTo[key] = to[key])
                newTo[i].status = ''
                return newTo
            })
        }
      }

    const resetBox = () => {
        setVisible(false)
        setComment("")
        // setModifiedText(props.record.text)
        // setAnonymizationActive(false)
    }

    const textObjTotext = () => { 
        const textList = []
        Object.entries(textObject).map(([i, item], index) => {
            if (item.status !== '') textList.push(item.status === 'gdpr' ? item.text : "[" + props.record.area + " ANONYMISED]")
            else textList.push(item.text)
        })
        return textList.join("")
    }

    const sendToAnalysis = () => {
        let record = props.record;
        const data = new FormData();
        // console.log("scanId " + props.scanId)
        let re = new RegExp("[\\\\\/\"]", "g");
        let re_enter = new RegExp("\n", "g");
        let userComment = comment.replace(re, "");
        axios.get(route + '/Report/ApiKey')
            .then(response => {
                if (response.status === 200){
                    let apiKey = response.data.api_key
                    // console.log(customerId)
                    userComment = userComment.replace(re_enter, " ");
                    let new_row = {
                        "api_key": apiKey,
                        // "text": modifiedText,
                        "text": textObjTotext(),
                        "category1": record.area,
                        "category2": record.category,
                        "category3": record.classification,
                        "comment": userComment,
                        "customer_id": "",
                    }
                    console.log(new_row)
                    data.append("row", JSON.stringify(new_row));
                    // console.log(customerId)

                    fetch('https://gdpr.ange.dk/gdpr/send_to_analysis', {
                        method: 'post',
                        body: data
                    })
                    .then(response => {
                        if (response.status === 200){
                            resetBox();
                            message.success("Record sent to analysis!");
            //                timer.current = setTimeout(() => {
            //                    setOpen(false);
            //                }, 800);
                        } else {
                            message.error("Unable to send comment, please try later...");
                            setVisible(false);
                        }
                    })
                    .catch(error => {
                        message.error("Unable to send comment, please try later...");
                        console.log(error);
                        setVisible(false);
                    })
                } else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error);
            });

    }

    const onRadioChange = (e) => {
        console.log('radio checked', e.target.value);
        setRadioValue(e.target.value);
        if (e.target.value === 1) setComment('Entry does not contain any Personal Identifiable Information')
        if (e.target.value === 2) setComment('Entry wrongfully tagged')
        if (e.target.value === 3) setComment('Other...')
        console.log(comment)
      };

    const displayText = <TestText textObject={textObject} anonymizeTextObject={anonymizeTextObject} anonymizeNonGdprTextObject={anonymizeNonGdprTextObject} record={props.record} />

    return (
        <div>
            <Button shape="circle" icon={<MailOutlined />} type="default" onClick={() => toggleModal(true)}/>
            <Modal
              title="Send record to analysis"
              visible={visible}
              onOk={() => sendToAnalysis()}
              onCancel={() => toggleModal(false)}
              width={700}
            >
                <Text strong="true" style={{color:'#7532a8', fontSize:16}}> Reason for reporting: </Text>
                <div style={{paddingLeft: 15, paddingTop: 10}}>
                <Radio.Group onChange={onRadioChange} value={radioValue}>
                    <Space direction="vertical">
                    <Radio value={1}>Text Entry does not contain any Personal Identifiable Information (GDPR Information)</Radio>
                    <Radio value={2}>Text Entry incorrectly embolded/highlighted</Radio>
                    <Radio value={3}>
                        Other...
                        {radioValue === 3 ? <Input.TextArea rows={3} style={{width: 500}} showCount maxLength={2000} placeholder="Enter the reason for reporting..." onChange={e => setComment(e.target.value)} /> : null}
                    </Radio>
                    </Space>
                </Radio.Group>
                </div>
                
                <div style={{minHeight: 16}}></div>
                <Text strong="true" style={{color:'#7532a8'}}> Reported text (click embolded word to anonymise): </Text>
                <div style={{minHeight: 5}}/>
                {displayText}
                <div style={{minHeight: 5}}/>
                <Row style={{marginTop: 8, marginBottom: 4}} gutter={8}>
                    <Col>
                        <Button type="primary" size="small" onClick={() => toggleAnonymyzeAll(true)}>Anonymise all</Button>
                    </Col>
                    <Col>
                        <Button type="default" size="small" onClick={() => toggleAnonymyzeAll(false)}>Reset anonymisation</Button>
                    </Col>
                </Row>
                <Text strong="true" style={{color:'#7532a8', marginTop: 12}}>Area: </Text>  {props.record.area} <br/>
                <Text strong="true" style={{color:'#7532a8'}}>Data Category: </Text> {props.record.category} <br/>
                <Text strong="true" style={{color:'#7532a8'}}>Classification: </Text>  {props.record.classification} <br/>

                {/* <Input.TextArea rows={4} value={modifiedText} onChange={e => setModifiedText(e.target.value)}/>
                <Row justify="space-between" style={{marginTop: 4}}>
                    <Col>
                        <Checkbox checked={anonymizationActive} disabled={anonymizationActive} onClick={() => toggleAnonymization()}>Anonymize entry</Checkbox>
                    </Col>
                    <Col>
                        <Button type="primary" disabled={!anonymizationActive} onClick={() => toggleAnonymization()}>Reset entry</Button>
                    </Col>
                </Row>
                <div style={{minHeight: 10}}></div>
                <Text strong="true" style={{color:'#7532a8', fontSize:16}}> Prototype text: </Text><br/> */}

            </Modal>
        </div>
    );
}
