import React, { useState, useEffect } from "react";


import { WarningOutlined  } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default function GenerateCsvButton(props) {

    const [superusers, setSuperusers] = useState([]);

    useEffect(() => {
        setSuperusers(props.superusers)
    }
    , [props.superusers])

    const title = <p>Other users can view your data. <br/>These users are: <br/>{superusers.map((user, i) => <span key={i}>{user} <br/></span>)}</p>

    return (
        <>
            {superusers.length > 0 &&
                <Tooltip placement='bottom' title={title}>
                    <WarningOutlined style={{color: 'orange', fontSize: '2.7em'}} />
                </Tooltip>
            }
        </>
    );
}