import React from 'react';

import { Button} from 'antd';

import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';

export default function FilterDrawerButton(props) {

    const toggleDrawer = () => {
        props.setDrawerVisible(!props.drawerVisible);
    };

    return (
            <Button type={props.drawerVisible ? "default": "primary"} onClick={toggleDrawer} icon={props.drawerVisible ? <UpCircleOutlined /> : <DownCircleOutlined />} style={{minWidth: 200}}>
              {props.drawerVisible ? "Hide filter options": "Show filter options"}
            </Button>
    );
};
