import React from "react";
import { Button, Tooltip } from 'antd';

export default function ClearResetFiltersButton(props) {
    return (
        <Tooltip mouseLeaveDelay={0} title={props.buttonText} placement="top">
            <Button 
                size="small" 
                style={props.style} 
                onClick={props.onClick}
                disabled={!!props.disabled}
                icon={props.icon}
            >
            </Button>
        </Tooltip>
    );
}
