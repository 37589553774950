import React from 'react';
import { Pie } from '@ant-design/charts';

// import { useThemeSwitcher } from "react-css-theme-switcher";
import { color_dict } from "../../colors";

function propsAreEqual(prevProps, nextProps) {
    return prevProps.data === nextProps.data
        && prevProps.barLegendLayout === nextProps.barLegendLayout
        && prevProps.barLegendPosition === nextProps.barLegendPosition;
}

const AreaDetails = React.memo(function AreaDetails(props) {

    // current app theme
    // const { currentTheme } = useThemeSwitcher();
    const currentTheme = "light"
    
    function getRandomColor(name){
        let name_sum = 0
        for (const character in name){
            name_sum += character.charCodeAt(0)
        }
        name_sum *=name_sum 
        name_sum *=name_sum
        let blueprint_numbers = [90, 200, 128]
        // let deviation = [80, 50, 100]
        let color = '#';
        blueprint_numbers.forEach((bp, i) => {
            let hex_str = ((Math.floor(Math.abs(Math.sin(name_sum + i*i*i) * 10000))) % 256).toString(16)
            if (hex_str.length < 2) hex_str = '0' + hex_str
            color += hex_str
        })
        return color
    }

    var config = {
      data: props.data,
      angleField: 'value',
      colorField: 'name',
      legend: {
        layout: !!props.barLegendLayout ? props.barLegendLayout : 'vertical',
        position: !!props.barLegendPosition ? props.barLegendPosition : 'right',
      },
      color: ({ name }) => { 
          if (Object.keys(color_dict).includes(name)) return color_dict[name]
          else return getRandomColor(name)
        },
      radius: 0.85,
      label: {
        type: 'spider',
        labelHeight: 28,
        content: '{name}\n{percentage}',
      },
      theme: currentTheme === 'dark' ? 'dark' : 'default',
//      theme: 'custom-theme',
      interactions: [{ type: props.multiSelect ? 'element-selected' : 'element-single-selected' }, { type: 'element-active' }],
    };

    return <Pie
        onReady={(plot) => {
            plot.on('element:click', ({data}) => {
                props.clickGraph(data.data.name)
            });
        }}
        {...config}
    />;
}, propsAreEqual);

export default AreaDetails;
