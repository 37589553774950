import React, { useState, useEffect, useMemo } from 'react';
import { Button, Transfer, Modal, Row, Col, Tooltip } from 'antd';
import { SettingOutlined } from "@ant-design/icons";


const FiltersTransfer = (props) => {

    const [transferOpen, setTransferOpen] = useState(false);

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        if (targetKeys !== props.chosen) setTargetKeys(props.chosen)
    }, [props.chosen])

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const handleOk = () => {
        props.onAccept(targetKeys)
        setTransferOpen(false)
    }

    const handleCancel = () => {
        setTargetKeys(props.chosen)
        setTransferOpen(false)
    }

    const data = useMemo(() => props.lookupData.map((value, i) => ({
        key: value,
        title: value,
        description: "",
    })), [props.lookupData])

    return (
        <>
            <Tooltip mouseLeaveDelay={0} title="Edit" placement="top">
                <Button 
                    size="small" 
                    icon={<SettingOutlined />} 
                    onClick={() => setTransferOpen(true)}
                    style={{marginRight: 4}}
                    disabled={!!props.disabled}
                >
                </Button>
            </Tooltip>
            <Modal
                title={"Select " + props.modalTitle + " to include in search results"}
                open={transferOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                footer={[
                    <Row justify="space-between">
                        <Col>
                            <Button onClick={() => setTargetKeys([])}>
                                Exclude all
                            </Button>
                            <Button onClick={() => setTargetKeys(props.lookupData)}>
                                Include all
                            </Button>
                        </Col>
                        <Col>
                            <Button key="back" onClick={handleCancel}>
                                Return
                            </Button>
                            <Button key="submit" type="primary" onClick={handleOk}>
                                Apply
                            </Button>
                        </Col>
                    </Row>
                ]}
                width={880}
                centered
                style={{}}
            >
                <Transfer
                    dataSource={data}
                    titles={['Excluded', 'Included']}
                    showSearch
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={onChange}
                    onSelectChange={onSelectChange}
                    render={(item) => item.title}
                    listStyle={{
                        width: 400,
                        height: 400,
                    }}
                />
            </Modal>
        
        </>
    );
};

export default FiltersTransfer;