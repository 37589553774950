import React, { useEffect, useState } from "react";
import moment from 'moment';

import GenerateCsvButton from '../components/GenerateCsvButton';

import { ExclamationCircleTwoTone, ArrowsAltOutlined, ArrowRightOutlined, MoreOutlined  } from '@ant-design/icons';
import { Button, Table, Row, Col, Typography, Tooltip, notification, Collapse, Popconfirm } from 'antd';
const { Text, Link } = Typography;
const { Panel } = Collapse;

var getFilepath = function (str) {
    return str.substring(0, str.lastIndexOf('/'));
}

export default function Report(props) {

    useEffect(() => {
        if (props.data.length === 0 && !props.loading){
            const args = {
                message: 'Apply filters',
                description: 'Choose and apply appropriate filters to view data per email.',
                duration: 5,
              };
              notification.open(args);
        }
    }, [])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [deleteData, setDeleteData] = useState([]);
    const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        console.log('selected row accounts: ', newSelectedRows.map(r => r.account))
        let deleteDataTemp = [];
        newSelectedRows.map(r => deleteDataTemp.push({"message_id": r.key, "account": r.account, "content_type": r.additional_info.sender === "Sharepoint" || r.additional_info.sender === "OneDrive" ? "file" : "message"}))
        setSelectedRowKeys(newSelectedRowKeys);
        console.log('deleteDataTemp: ', deleteDataTemp)
        setDeleteData(deleteDataTemp)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
        { title: <Text>Date</Text>, dataIndex: 'date', key: 'date', width: 160,
            sorter: (a, b) => moment(a.date, 'DD/MM/YYYY hh:mm:ss').diff(moment(b.date, 'DD/MM/YYYY hh:mm:ss')), render: (val, record) => <Text> {record.date} </Text> 
        },
        { title: 'Account', dataIndex: 'account', key: 'account', width: 250, sorter: (a, b) => a.account > b.account, render: (val) => <Text strong>{val}</Text> },
        { title: 'Message / File Information', dataIndex: 'additional_info', key: 'additional_info', width: 450,
            render: (val, record) => {
                if (val.sender === 'Sharepoint' || val.sender === 'OneDrive'  ) {
                    return (
                        <React.Fragment>
                            <Text strong>Location: </Text><Text>{val.sender}</Text><br/>
                            <Text strong>Filename: </Text><Text>{val.subject}</Text><br/>
                            <Text strong>Path: </Text>
                            <Text>        
                                <Link target="_blank" href={getFilepath(val.recipients[0])}>{val.recipients[0].length > 60 ? val.recipients[0].slice(0,59) + '...' : val.recipients[0]}</Link>
                                    {val.recipients[0].length > 60 &&
                                        <Tooltip overlayInnerStyle={{minWidth:400}} title={val.recipients[0]} placement="right">
                                            <MoreOutlined />
                                        </Tooltip>
                                    }
                            </Text>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <Text strong>From: </Text><Text>{val.sender}</Text><br/>
                            <Text strong>To: </Text>
                            <Text>
                                {val.recipients.length > 1 
                                    ?
                                    <Tooltip overlayInnerStyle={{minWidth:300}} title={<Row>{(val.recipients).map(r => {return <Col span={24}>{r}</Col>})}</Row>} placement="right">
                                        <Text strong>
                                            Multiple recipients... <ArrowsAltOutlined />
                                        </Text>
                                    </Tooltip> 
                                    : val.recipients
                                }
                            </Text><br/>
                            <Text strong>Folder: </Text><Text>{record.additional_info.folder}</Text><br/>
                            {val.cc.length > 0 && <React.Fragment>
                                <Text strong>CC: </Text><Text>
                                {val.cc.length > 1 
                                    ?
                                    <Tooltip overlayInnerStyle={{minWidth:300}} title={<Row>{(val.cc).map(r => {return <Col span={24}>{r}</Col>})}</Row>} placement="right">
                                        <Text strong>
                                            Multiple CCs... <ArrowsAltOutlined />
                                        </Text>
                                    </Tooltip> 
                                    : val.cc
                                }
                                </Text><br/>
                            </React.Fragment>
                            }
                            <Text strong>Subject: </Text><Text>{record.additional_info.subject}</Text>
                        </React.Fragment>
                    )
                }
            }
        },
        // { title: 'Message / File Information', dataIndex: 'additional_info', key: 'additional_info', 
        //     render: (val, record) => 
        //         <React.Fragment>
        //             {val.sender} <ArrowRightOutlined style={{marginRight: 4, marginLeft: 4}}/>
        //             {val.recipients.length > 1 
        //                 ?
        //                 <Tooltip overlayInnerStyle={{minWidth:300}} title={<Row>{(val.recipients).map(r => {return <Col span={24}>{r}</Col>})}</Row>} placement="right">
        //                     <Text strong>
        //                         Multiple recipients... <ArrowsAltOutlined />
        //                     </Text>
        //                 </Tooltip> 
        //                 : val.sender === "Sharepoint" || val.sender === "OneDrive"
        //                     ?   <React.Fragment>
        //                             <Link target="_blank" href={val.recipients[0]}>{val.recipients[0].length > 80 ? val.recipients[0].slice(0,79) + '...' : val.recipients[0]}</Link>
        //                             {val.recipients[0].length > 80 &&
        //                             <Tooltip overlayInnerStyle={{minWidth:300}} title={val.recipients[0]} placement="right">
        //                                 <MoreOutlined />
        //                             </Tooltip>
        //                             }
        //                         </React.Fragment>
        //                     : <Text>{val.recipients}</Text>
        //             }
        //             {val.cc.length > 1 
        //                 ?
        //                 <Tooltip overlayInnerStyle={{minWidth:300}} title={<Row>{(val.cc).map(r => {return <Col span={24}>{r}</Col>})}</Row>} placement="right">
        //                     <Text strong style={{marginLeft: 8}}>
        //                         Multiple CCs... <ArrowsAltOutlined />
        //                     </Text>
        //                 </Tooltip> 
        //                 : val.cc.length > 0
        //                     ? <Text style={{marginLeft: 8}}>CC: {val.cc}</Text> 
        //                     : null
        //             }
        //         </React.Fragment>
        // },
        { title: 'GDPR Components', dataIndex: 'component_list', key: 'component_list', width: 350,
            render: (val, record) => 
                <React.Fragment>
                    {val.map((comp, i) => {
                        return (
                            <Row style={i > 0 ? {marginTop: 8} : {}}>
                                <span>
                                    <Text italic={comp.content_type !== "body"}>{comp.content_type !== "body" ? comp.file_name : "Message body" } </Text>
                                    <ArrowRightOutlined style={{marginRight: 4, marginLeft: 4}}/>
                                    <Text strong> {comp.violations} GDPR {comp.violations > 1 ? "entries" : "entry"} </Text>
                                    {comp.sensitive_violations > 0 && <Tooltip mouseLeaveDelay={0} title="Contains sensitive data!" placement="top"><ExclamationCircleTwoTone twoToneColor="#ba3434" style={{marginLeft:3}} /></Tooltip>}
                                </span>
                            </Row>
                        )
                    })}
                </React.Fragment>
        },
    ];

    return (
        <div>
            {hasSelected ? 
                <Popconfirm title="Sure to delete?" onConfirm={() => props.handleDelete(selectedRowKeys, deleteData) & setSelectedRowKeys([]) & setDeleteData([])}>
                    <Button type="primary">
                    Delete
                    </Button>
                </Popconfirm>
            : ''}
            <Table
                bordered
                rowSelection={rowSelection}
                columns={columns}
                expandable={{ 
                    expandedRowRender: record => {return props.accountSubTable(record)},
                    rowExpandable: record => record.components_gdpr > 0,
                }}
                dataSource={props.data}
                style={{maxHeight: '100%', height: '100%'}}
                scroll={props.drawerVisible ? { y: props.intFrameHeight - 68 - 335} : { y: props.intFrameHeight - 68 - 100 }}
                loading={props.loading}
                size="small"
                showHeader={true}
                pagination={{ pageSize: 100 }}
                footer={() =>
                    <React.Fragment>
                        <Row gutter={8} justify="space-between">
                            <Col>
                                {props.filterDrawer}
                            </Col>
                            <Col>
                                <GenerateCsvButton disabled={props.data.length === 0} generateCsv={() => props.generateCsv(null, false, null, [], [])} />
                            </Col>
                        </Row>
                    </React.Fragment>
                }
                />
        </div>
    );
}