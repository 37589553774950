import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';

import {UserContext} from '../user-context';
import { color_dict } from "../colors";
import ReportButton from './ReportButton';

import { MoreOutlined } from '@ant-design/icons';
import { Table, Typography, Tooltip, Tag } from 'antd';
const { Text } = Typography;

const new_resp = [

    {

      "file_name": "",

      "content_type": "body",

      "violation": "Mvh Nicolas On 2016-12-07 1814, Kim Rydhof Thor Hansen wrote Jeg har arbejdet en måned i ferieåret 1516 (optjent 2014), men har checket min kalender for maj 2015 og der holdt jeg ingen ferie.",

      "area": "Work",

      "category": "GDPR",

      "classification": "Absence: Vacation",

      "language": "dk"

    },

    {

      "file_name": "",

      "content_type": "body",

      "violation": "Jeg har optjent ferie i 2015 i 5 måneder, dvs. 30  5  12 = 12½ feriedage Kim",

      "area": "Legal",

      "category": "GDPR Confidential",

      "classification": "Absence: Vacation",

      "language": "dk"

    },

    {

      "file_name": "Olek bolek",

      "content_type": "body",

      "violation": "Jeg har optjent ferie i 2015 i 5 måneder, dvs. 30  5  12 = 12½ feriedage Kim",

      "area": "Affiliation",

      "category": "GDPR Sensitive",

      "classification": "Absence: Vacation",

      "language": "dk"

    }

]

export default function AccountSubTable(props){
    
    const [data, setData] = useState([]);
    const [loadingRecords, setLoadingRecords] = useState(false);
    
    const { route } = useContext(UserContext)

    useEffect(() => {
        // Determine if apply filters or not
        setLoadingRecords(true);

        if (props.filtersAppliedFlag){
            let update = [];
            update.push({
                "message_id": props.record.key,
                "areas": props.filterState.areas,
                "categories": props.filterState.categories,
                "classifications": props.filterState.classifications,
                "content_types": props.filterState.content_types,
                "from": props.filterState.dateFrom == null ? null : props.filterState.dateFrom.format('DD/MM/YYYY'),
                "to": props.filterState.dateTo == null ? null : props.filterState.dateTo.format('DD/MM/YYYY'),
                "view_non_gdpr": props.showNonGdprComponents
            })
            update = JSON.stringify(update);

            const data = new FormData();
            data.append('data', update)

            axios.post(route + '/Report/Data/AccountFiltered', data, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                }
            })
            .then(response => {
                if (response.status === 200){
                    // if props.record.account is included in props.blockedViewRightsAcc then change row.violation to "View blocked"
                    if (props.blockedViewRightsAcc.includes(props.record.account)) {
                        response.data.forEach(row => {
                            row.violation = "You don't have the required data viewing rights of chosen account(s). Data Viewing Rights may be managed in the Configuration Page."
                        })
                    }
                    setData(response.data)
                } else {
                    console.log(response)
                }
                setLoadingRecords(false);
            })
            .catch(error => {
                console.log(error)
                setLoadingRecords(false);
            })
        } else {
            axios.get(route + '/Report/Data/Account', {params: {message_id: props.record.key, view_non_gdpr: props.showNonGdprComponents}})
                .then(response => {
                    if (response.status === 200){
                        if (props.blockedViewRightsAcc.includes(props.record.account)) {
                            response.data.forEach(row => {
                                row.violation = "You don't have the required data viewing rights of chosen account(s). Data Viewing Rights may be managed in the Configuration Page."
                            })
                        }
                        setData(response.data)
                    } else {
                        console.log(response);
                    }
                    setLoadingRecords(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoadingRecords(false);
                });
            }
            if (window.o365_config.USE_DEVELOPMENT_BUILD) setData(new_resp)
    }, [props.triggerApplyFilters])


    const columns = [
        { title: 'Entry location', dataIndex: 'file_name', key: 'file_name', width: 200, 
            render: (val, record) =><Text italic={record.content_type !== 'body'}>{record.content_type !== 'body' ? val : "Message body"}</Text>
        },
        // { title: 'Content Type', dataIndex: 'content_type', key: 'content_type', width: 150 },      
        { title: 'Entry', dataIndex: 'violation', key: 'violation', 
            render: (val, record) => {
                const text = <div dangerouslySetInnerHTML={{__html: val}}></div>
                return (
                    <React.Fragment>
                        {/* <Text>{val.length > 500 ? val.slice(0,499) + '...' : val}</Text>
                        {val.length > 500 &&
                        <Tooltip overlayInnerStyle={{minWidth: 570}} title={val} placement="right">
                            <MoreOutlined />
                        </Tooltip>
                        } */}
                        {text}
                    </React.Fragment>
                )
            }
        },
        { title: 'Area', dataIndex: 'area', key: 'area', width: 100, sorter: (a, b) => a.area > b.area,
            render: (val, _record) => <Tag color={color_dict[val]}>{val}</Tag>
        },
        { title: 'Category', dataIndex: 'category', key: 'category', width: 200, sorter: (a, b) => a.category > b.category,
            render: (val, record) => <Tag color={color_dict[val]}>{val}</Tag>
        },
        { title: 'Classification', dataIndex: 'classification', key: 'classification', width: 200, sorter: (a, b) => a.classification > b.classification },
        {
            title: 'Action', dataIndex: '', key: 'y', width: 80,
            render: (title, record) => <ReportButton record={record} />,
        },
    ];


    return <Table
        sticky
        columns={columns}
        loading={loadingRecords}
        pagination={false}
        // pagination={{ pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true, size: "small", hideOnSinglePage: false, style:{marginBottom: 0} }}
        bordered={true}
        // expandable={{
        //     expandedRowRender: record => <ViolationsSubTable violationRecord={record} {...props}/>,
        //     rowExpandable: record => record.violations > 0
        // }}
        dataSource={data}
        size="small"
        scroll={{ y: 0.59 * window.innerHeight, scrollToFirstRowOnChange: true }}
        style={{marginBottom: 12}}
    />;
}
