import React, {useState, useEffect, useMemo, useContext, useRef } from "react";
import moment from 'moment';
import axios from 'axios';

import { Transfer, Row, Col, Button, Typography, Tag, Checkbox, Card, DatePicker, Input, Radio, Form, Space, Tooltip, message } from 'antd';
import { ArrowRightOutlined, MailOutlined, PoweroffOutlined, SaveOutlined, WarningOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';

import SelectAccountsTransfer from "./SelectAccountsTransfer";
import GrantViewDataConsent from "./GrantViewDataConsent";
import { UserContext } from '../user-context';

const { Text } = Typography;

const tagCountFilter = 6


// Custom hook used to prevent on mount execution with useEffects with dependencies
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
    }, deps);
};

export default function AdminView(props) {

    const { route } = useContext(UserContext);

    // layout numbers
    const areaCardHeight = 400
    const overviewHeight = props.intFrameHeight - 68
    const tableHeight = overviewHeight - areaCardHeight - 112 > 259 ? overviewHeight - areaCardHeight - 112 : 147

    // visuals
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [triggeringScan, setTriggeringScan] = useState(false)

    // config fetch state
    const [configState, setConfigState] = useState({
        // scan
        emailsToScan: [],
        emailsToSkip: [],
        scanOrSkip: "scan",
        frequency: 0,
        // rescan 
        emailsToScanRescan: [],
        emailsToSkipRescan: [],
        scanOrSkipRescan: "scan",
        dateFrom: null,
        dateTo: null,
        // consent
        consentGranted: [],
    })
    const [configLoaded, setConfigLoaded] = useState(false)

    // UI config state
    const [currentState, setCurrentState] = useState({
        // scan
        emailsToScan: [],
        emailsToSkip: [],
        scanOrSkip: "scan",
        frequency: 0,
        // rescan 
        emailsToScanRescan: [],
        emailsToSkipRescan: [],
        scanOrSkipRescan: "scan",
        dateFrom: null,
        dateTo: null,
        // consent
        consentGranted: [],
    })

    // accounts lookup
    const [accounts, setAccounts] = useState([])
    
    // Scan Configuration

    const [frequencyEditing, setFrequencyEditing] = useState("")
    const [isEditingFrequency, setIsEditingFrequency] = useState(false)

    // Rescan Configuration

    const [dateFromEditing, setDateFromEditing] = useState(null);
    const [dateToEditing, setDateToEditing] = useState(null);
    const [isEditingDateRange, setIsEditingDateRange] = useState(false)


    // CONFIG DATA FETCHING AND SAVING
    const resetStateToLastSaved = () => {
        // scan
        setFrequencyEditing(configState.frequency)
        // rescan
        setDateFromEditing(configState.dateFrom)
        setDateToEditing(configState.dateTo)
        // current state
        setCurrentState({...configState})
    }

    const fetchConfigData = () => {
        setLoading(true)
        axios.get(route + '/Config')
            .then(response => {
                if (response.status === 200){
                    // lookup
                    setAccounts(response.data.accounts)
                    // scan
                    setFrequencyEditing(response.data.scan_frequency)
                    // rescan
                    setDateFromEditing(!!response.data.date_from ? moment(response.data.date_from, 'DD/MM/YYYY') : null)
                    setDateToEditing(!!response.data.date_to ? moment(response.data.date_to, 'DD/MM/YYYY') : null)
                    // config state
                    setConfigState({
                        // scan
                        emailsToScan: response.data.mails_to_scan,
                        emailsToSkip: response.data.mails_to_skip,
                        scanOrSkip: response.data.scan_or_skip,
                        frequency: response.data.scan_frequency,
                        // rescan 
                        emailsToScanRescan: response.data.mails_to_scan_rescan,
                        emailsToSkipRescan: response.data.mails_to_skip_rescan,
                        scanOrSkipRescan: response.data.scan_or_skip_rescan,
                        dateFrom: !!response.data.date_from ? moment(response.data.date_from, 'DD/MM/YYYY') : null,
                        dateTo: !!response.data.date_to ? moment(response.data.date_to, 'DD/MM/YYYY') : null,
                        consentGranted: response.data.consent_granted,
                    })
                    // current state
                    setCurrentState({
                        // scan
                        emailsToScan: response.data.mails_to_scan,
                        emailsToSkip: response.data.mails_to_skip,
                        scanOrSkip: response.data.scan_or_skip,
                        frequency: response.data.scan_frequency,
                        // rescan 
                        emailsToScanRescan: response.data.mails_to_scan_rescan,
                        emailsToSkipRescan: response.data.mails_to_skip_rescan,
                        scanOrSkipRescan: response.data.scan_or_skip_rescan,
                        dateFrom: !!response.data.date_from ? moment(response.data.date_from, 'DD/MM/YYYY') : null,
                        dateTo: !!response.data.date_to ? moment(response.data.date_to, 'DD/MM/YYYY') : null,
                        consentGranted: response.data.consent_granted,
                    })
                    // Delay execution to prevent preemptive config saving
                    setTimeout(() => setConfigLoaded(true), 1000)
                } else {
                    message.error("Could not fetch configuration data...")
                    console.log(response);
                }
                setLoading(false)
            })
            .catch(error => {
                message.error("Could not fetch configuration data...")
                console.log(error);
                setLoading(false)
            });
        if (window.o365_config.USE_DEVELOPMENT_BUILD) {
            setAccounts(window.o365_config.data.filters_dict.accounts)
            setDateFromEditing(props.minFrom)
            setDateToEditing(props.maxTo)
            setFrequencyEditing("0 0 * * * *")
            setConfigState({
                // scan
                emailsToScan: window.o365_config.data.filters_dict.accounts,
                emailsToSkip: [],
                scanOrSkip: "scan",
                frequency: "0 0 * * * *",
                // rescan
                emailsToScanRescan: window.o365_config.data.filters_dict.accounts,
                emailsToSkipRescan: [],
                scanOrSkipRescan: "skip",
                dateFrom: props.minFrom,
                dateTo: props.maxTo,
                consentGranted: [],
            })
            setCurrentState({
                // scan
                emailsToScan: window.o365_config.data.filters_dict.accounts,
                emailsToSkip: [],
                scanOrSkip: "scan",
                frequency: "0 0 * * * *",
                // rescan
                emailsToScanRescan: window.o365_config.data.filters_dict.accounts,
                emailsToSkipRescan: [],
                scanOrSkipRescan: "skip",
                dateFrom: props.minFrom,
                dateTo: props.maxTo,
                consentGranted: [],
            })
            // Delay execution to prevent preemptive config saving
            setTimeout(() => setConfigLoaded(true), 1000)
        }
    }

    const saveConfig = () => {
        setSaving(true)
        message.info("Saving configuration...")
        let update = [];
        const { emailsToScan, emailsToSkip, frequency, scanOrSkip, emailsToScanRescan, emailsToSkipRescan, scanOrSkipRescan, dateFrom, dateTo, consentGranted } = currentState
        update.push({
            // scan config
            "mails_to_scan": emailsToScan,
            "mails_to_skip": emailsToSkip,
            "scan_frequency": frequency,
            "scan_or_skip": scanOrSkip,
            // rescan config
            "mails_to_scan_rescan": emailsToScanRescan,
            "mails_to_skip_rescan": emailsToSkipRescan,
            "scan_or_skip_rescan": scanOrSkipRescan,
            "date_from_rescan": dateFrom == null ? null : dateFrom.format('DD/MM/YYYY'),
            "date_to_rescan": dateTo == null ? null : dateTo.format('DD/MM/YYYY'),
            // consent
            "consent_granted": consentGranted,
        })
        update = JSON.stringify(update);

        const data = new FormData();
        data.append('data', update)

        axios.post(route + '/Config', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                if (response.status === 200){
                    message.success('Configuration saved!')
                    setConfigState({
                        // scan
                        emailsToScan: emailsToScan,
                        emailsToSkip: emailsToSkip,
                        scanOrSkip: scanOrSkip,
                        frequency: frequency,
                        //rescan
                        emailsToScanRescan: emailsToScanRescan,
                        emailsToSkipRescan: emailsToSkipRescan,
                        scanOrSkipRescan: scanOrSkipRescan,
                        dateFrom: dateFrom,
                        dateTo: dateTo,
                        // consent
                        consentGranted: consentGranted,
                    })
                } else {
                    message.error("Failed to save configuration, resetting...")
                    console.log(response);
                    // TODO complete resetting
                    resetStateToLastSaved()
                }
                setSaving(false)
            })
            .catch(error => {
                message.error("Could not save configuration, resetting...")
                console.log(error);
                // TODO complete resetting
                resetStateToLastSaved()
                setSaving(false)
            });
    }

    const triggerScan = () => {
        setTriggeringScan(true)
        axios.get(route + '/Scanner/Start')
            .then(response => {
                if (response.status === 200){
                    message.info(response.data.message)
                } else {
                    message.error("Could not trigger the scanner...")
                    console.log(response);
                }
                setTriggeringScan(false)
            })
            .catch(error => {
                message.error("Could not trigger the scanner...")
                console.log(error);
                setTriggeringScan(false)
            });

    }

    const modifyCurrentState = (key, value) => {
        setCurrentState(cs => { return {...cs, [key]: value} })
    }

    // fetch data on mount
    useEffect(() => {
        fetchConfigData()
    }, [])

    // save config on changes
    useEffect(() => {
        if (configLoaded){
            const configKeys = ["emailsToScan", "emailsToSkip", "frequency", "scanOrSkip", "emailsToScanRescan", "emailsToSkipRescan", "scanOrSkipRescan", "dateFrom", "dateTo", "consentGranted"]
            for (let i = 0; i < configKeys.length; i++) {
                if (configState[configKeys[i]] != currentState[configKeys[i]]) {
                    // console.log("Difference!", configKeys[i], configState[configKeys[i]], currentState[configKeys[i]])
                    saveConfig()
                    break
                }
            }
        }
    }, [currentState])
    
    const { emailsToScan, emailsToSkip, frequency, scanOrSkip, emailsToScanRescan, emailsToSkipRescan, scanOrSkipRescan, dateFrom, dateTo, consentGranted } = currentState

    const scanConfigForm = 
        <Form layout="vertical">
            <Row gutter={16}>
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item 
                        name="asc"
                        label={
                            <Space size={8}>
                                <div>
                                    <Text strong> Accounts scanned </Text>
                                </div>
                                <div>
                                    <SelectAccountsTransfer 
                                        intFrameWidth={props.intFrameWidth}
                                        lookupData1={accounts}
                                        lookupData2={accounts}
                                        chosen1={emailsToScan}
                                        chosen2={emailsToSkip}
                                        modalTitle="accounts"
                                        onAccept1={e => modifyCurrentState("emailsToScan", e)}
                                        onAccept2={e => modifyCurrentState("emailsToSkip", e)}
                                        selectSkip={scanOrSkip}
                                        setSelectSkip={e => modifyCurrentState("scanOrSkip", e)}
                                    />
                                </div>
                            </Space>
                        } 
                    >
                        <>
                            {scanOrSkip === 'skip' && emailsToSkip.length === 0 && <Tag disabled>All accounts</Tag>}
                            {scanOrSkip === 'skip' && emailsToSkip.length > 0 && <Tag disabled>All accounts except skipped</Tag>}
                            {scanOrSkip === 'scan' && emailsToScan.length === accounts.length && <Tag disabled>All accounts</Tag>}
                            {scanOrSkip === 'scan' && emailsToScan.length !== accounts.length &&
                                <>
                                    {emailsToScan.slice(0, tagCountFilter).map(acc => {return <Tag style={{marginTop: 4}}>{acc}</Tag>})}
                                    {emailsToScan.length > tagCountFilter && <Tag style={{marginTop: 4}}>{"+ " + (Number(emailsToScan.length) - tagCountFilter) + " others..."}</Tag>}
                                </>
                            }
                        </>
                    </Form.Item>
                </Col>
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item 
                    name="ask"
                        label={
                            <Space size={8}>
                                <div>
                                    <Text strong> Accounts skipped </Text>
                                </div>
                                <div>
                                    <SelectAccountsTransfer 
                                        intFrameWidth={props.intFrameWidth}
                                        lookupData1={accounts}
                                        lookupData2={accounts}
                                        chosen1={emailsToScan}
                                        chosen2={emailsToSkip}
                                        modalTitle="accounts"
                                        onAccept1={e => modifyCurrentState("emailsToScan", e)}
                                        onAccept2={e => modifyCurrentState("emailsToSkip", e)}
                                        selectSkip={scanOrSkip}
                                        setSelectSkip={e => modifyCurrentState("scanOrSkip", e)}
                                    />
                                </div>
                            </Space>
                        }  
                    >
                        <>
                            {scanOrSkip === 'scan' && <Tag disabled>None</Tag>}
                            {scanOrSkip === 'skip' && emailsToSkip.length === 0 && <Tag disabled>None</Tag>}
                            {scanOrSkip === 'skip' && emailsToSkip.length !== 0 &&
                                <>
                                    {emailsToSkip.slice(0, tagCountFilter).map(acc => {return <Tag style={{marginTop: 4}}>{acc}</Tag>})}
                                    {emailsToSkip.length > tagCountFilter && <Tag style={{marginTop: 4}}>{"+ " + (Number(emailsToScan.length) - tagCountFilter) + " others..."}</Tag>}
                                </>
                            }
                        </>
                    </Form.Item>
                </Col>
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item 
                        name="sf"
                        label={
                            <Space size={8}>
                                <div>
                                    <Text strong> Scanning frequency </Text>
                                </div>
                                <div>
                                    <Tooltip mouseLeaveDelay={0} placement="top" title={isEditingFrequency ? "Save" : "Edit"}>
                                        <Button 
                                            icon={isEditingFrequency ? <SaveOutlined /> : <EditOutlined />} 
                                            size="small" 
                                            onClick={() => {
                                                if (isEditingFrequency) {
                                                    modifyCurrentState("frequency", frequencyEditing)
                                                }
                                                setIsEditingFrequency(editing => !editing);
                                            }}
                                        >
                                        </Button>
                                    </Tooltip>
                                </div>
                                {isEditingFrequency &&
                                    <div>
                                        <Tooltip mouseLeaveDelay={0} placement="top" title={"Cancel"}>
                                            <Button 
                                                icon={<CloseOutlined />} 
                                                size="small" 
                                                onClick={() => {
                                                    setFrequencyEditing(frequency);
                                                    setIsEditingFrequency(editing => !editing);
                                                }}
                                            >
                                            </Button>
                                        </Tooltip>
                                    </div>
                                }
                            </Space>
                        }
                    >
                        {isEditingFrequency
                            ?
                            <>
                                <span style={{display: "inline-block", marginRight: 8}}>
                                    <Text>Cron: </Text>
                                </span>
                                <span style={{display: "inline-block", marginRight: 8}}>
                                    <Input
                                        value={frequencyEditing}
                                        onChange={e => setFrequencyEditing(e.target.value)}
                                        style={{width: 150}}
                                    />
                                </span>
                                {/* <span style={{display: "inline-block", marginRight: 4}}>
                                    <Text>minutes</Text>
                                </span> */}
                            </>
                            :
                            <span style={{display: "inline-block", marginRight: 4}}>
                                {/* <Text>{"Every " + frequency + " minutes"}</Text> */}
                                <Text>{"Cron: " + frequency}</Text>
                            </span>
                        }
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    const rescanConfigForm = 
        <Form layout="vertical">
            <Row gutter={12} justify="space-between">
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item 
                        name="asc"
                        label={
                            <Space size={8}>
                                <div>
                                    <Text strong> Accounts scanned </Text>
                                </div>
                                <div>
                                    <SelectAccountsTransfer 
                                        intFrameWidth={props.intFrameWidth}
                                        lookupData1={accounts}
                                        lookupData2={accounts}
                                        chosen1={emailsToScanRescan}
                                        chosen2={emailsToSkipRescan}
                                        modalTitle="accounts"
                                        onAccept1={e => modifyCurrentState("emailsToScanRescan", e)}
                                        onAccept2={e => modifyCurrentState("emailsToSkipRescan", e)}
                                        selectSkip={scanOrSkipRescan}
                                        setSelectSkip={e => modifyCurrentState("scanOrSkipRescan", e)}
                                    />
                                </div>
                            </Space>
                        }
                    >
                        <>
                            {scanOrSkipRescan === 'skip' && emailsToSkipRescan.length === 0 && <Tag disabled>All accounts</Tag>}
                            {scanOrSkipRescan === 'skip' && emailsToSkipRescan.length > 0 && <Tag disabled>All accounts except skipped</Tag>}
                            {scanOrSkipRescan === 'scan' && emailsToScanRescan.length === accounts.length && <Tag disabled>All accounts</Tag>}
                            {scanOrSkipRescan === 'scan' && emailsToScanRescan.length !== accounts.length &&
                                <>
                                    {emailsToScanRescan.slice(0, tagCountFilter).map(acc => {return <Tag style={{marginTop: 4}}>{acc}</Tag>})}
                                    {emailsToScanRescan.length > tagCountFilter && <Tag style={{marginTop: 4}}>{"+ " + (Number(emailsToScanRescan.length) - tagCountFilter) + " others..."}</Tag>}
                                </>
                            }
                        </>
                    </Form.Item>
                </Col>
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item name="ask"
                        label={
                            <Space size={8}>
                                <div>
                                    <Text strong> Accounts skipped </Text>
                                </div>
                                <div>
                                    <SelectAccountsTransfer 
                                        intFrameWidth={props.intFrameWidth}
                                        lookupData1={accounts}
                                        lookupData2={accounts}
                                        chosen1={emailsToScanRescan}
                                        chosen2={emailsToSkipRescan}
                                        modalTitle="accounts"
                                        onAccept1={e => modifyCurrentState("emailsToScanRescan", e)}
                                        onAccept2={e => modifyCurrentState("emailsToSkipRescan", e)}
                                        selectSkip={scanOrSkipRescan}
                                        setSelectSkip={e => modifyCurrentState("scanOrSkipRescan", e)}
                                    />
                                </div>
                            </Space>
                        }
                    >
                        <>
                            {scanOrSkipRescan === 'scan' && <Tag disabled>None</Tag>}
                            {scanOrSkipRescan === 'skip' && emailsToSkipRescan.length === 0 && <Tag disabled>None</Tag>}
                            {scanOrSkipRescan === 'skip' && emailsToSkipRescan.length !== 0 &&
                                <>
                                    {emailsToSkipRescan.slice(0, tagCountFilter).map(acc => {return <Tag style={{marginTop: 4}}>{acc}</Tag>})}
                                    {emailsToSkipRescan.length > tagCountFilter && <Tag style={{marginTop: 4}}>{"+ " + (Number(emailsToScanRescan.length) - tagCountFilter) + " others..."}</Tag>}
                                </>
                            }
                        </>
                    </Form.Item>
                </Col>
                <Col xl={8} lg={12} md={24} sm={24} xs={24} style={{whiteSpace: "nowrap"}}>
                    <Form.Item 
                        name="dr" 
                        label={
                            <Space size={8}>
                                <div>
                                    <Text strong> E-mail date range </Text>
                                </div>
                                <div>
                                    <Tooltip mouseLeaveDelay={0} placement="top" title={isEditingDateRange ? "Save" : "Edit"}>
                                        <Button 
                                            icon={isEditingDateRange ? <SaveOutlined /> : <EditOutlined />} 
                                            size="small" 
                                            onClick={() => {
                                                if (isEditingDateRange) {
                                                    modifyCurrentState("dateFrom", dateFromEditing)
                                                    modifyCurrentState("dateTo", dateToEditing)
                                                }
                                                setIsEditingDateRange(editing => !editing)
                                            }}
                                        >
                                        </Button>
                                    </Tooltip>
                                </div>
                                {isEditingDateRange &&
                                    <div>
                                        <Tooltip mouseLeaveDelay={0} placement="top" title={"Cancel"}>
                                            <Button 
                                                icon={<CloseOutlined />} 
                                                size="small" 
                                                onClick={() => {
                                                    setDateFromEditing(dateFrom)
                                                    setDateToEditing(dateTo)
                                                    setIsEditingDateRange(editing => !editing)
                                                }}
                                            >
                                            </Button>
                                        </Tooltip>
                                    </div>
                                }
                            </Space>
                        }
                    >
                        {isEditingDateRange
                            ? <Space size={8}>
                                <span style={{display: "inline-block"}}>
                                    <DatePicker
                                        fullWidth
                                        allowClear={false}
                                        disabledDate={(current) => current && !!props.minFrom && !!dateToEditing && (current < moment(props.minFrom, 'DD/MM/YYYY').startOf('day') || current > moment(dateToEditing, 'DD/MM/YYYY').endOf('day'))}
                                        value={dateFromEditing}
                                        format={props.dateFormat}
                                        allowEmpty={false}
                                        onChange={(date) => setDateFromEditing(date)}
                                    />
                                </span>
                                <span style={{display: "inline-block"}}>
                                    <ArrowRightOutlined />
                                </span>
                                <span style={{display: "inline-block"}}>
                                    <DatePicker
                                        fullWidth
                                        allowClear={false}
                                        disabledDate={(current) => current && !!props.maxTo && !!dateFromEditing && (current > moment(props.maxTo, 'DD/MM/YYYY').endOf('day') || current < moment(dateFromEditing, 'DD/MM/YYYY').endOf('day'))}
                                        value={dateToEditing}
                                        format={props.dateFormat}
                                        allowEmpty={false}
                                        onChange={(date) => setDateToEditing(date)}
                                    />
                                </span>
                            </Space>
                            : <Space size={8}>
                                <span style={{display: "inline-block"}}>
                                    {moment(dateFrom).format(props.dateFormat)}
                                </span>
                                <span style={{display: "inline-block"}}>
                                    <ArrowRightOutlined />
                                </span>
                                <span style={{display: "inline-block"}}>
                                    {moment(dateTo).format(props.dateFormat)}
                                </span>
                            </Space>
                        }
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    
    const viewDataConsentForm =
        <Form layout="vertical">
            <Row gutter={16}>
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item 
                        name="asc"
                        label={
                            <Space size={8}>
                                <div>
                                    <Text strong> Data viewing rights </Text>
                                </div>
                                <div>
                                    <GrantViewDataConsent 
                                        intFrameWidth={props.intFrameWidth}
                                        lookupData={accounts}
                                        chosen={consentGranted}
                                        onAccept={e => modifyCurrentState("consentGranted", e)}
                                    />
                                </div>
                            </Space>
                        } 
                    >
                        <>
                            {consentGranted.length === 0 && <Tag disabled>No consent granted</Tag>}
                            {consentGranted.length > 0 && consentGranted.length !== accounts.length && <Tag disabled>No. of consents: {consentGranted.length}</Tag>}
                            {consentGranted.length === accounts.length && <Tag disabled>All accounts granted consent</Tag>}
                        </>
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    return (
        <React.Fragment>
            <Row gutter={8}>
                <Col span={24} style={{marginBottom: 8}}>
                    <Card
                        title="Edit scanner configuration"
                        bordered={false}
                        style={{ width: "100%"}}
                        loading={loading}
                        // extra={
                        //     <>
                        //         <Button
                        //             type="primary"
                        //             onClick={() => triggerScan()}
                        //             style={{marginRight: 8}}
                        //             icon={<PoweroffOutlined />}
                        //             loading={triggeringScan}
                        //         >
                        //             Trigger a scan now
                        //         </Button>
                        //     </>
                        // }
                    >
                        {scanConfigForm}
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{marginBottom: 8}}>
                    <Card
                        title="Configure a rescan of your choice"
                        bordered={false}
                        style={{ width: "100%"}}
                        loading={loading}
                        extra={
                            <>
                                <Button disabled type="primary" style={{marginRight: 8, paddingBottom: 4}} icon={<PoweroffOutlined />}>Rescan for new results</Button>
                                <Button disabled type="default" style={{marginRight: 8, paddingBottom: 4}}  icon={<MailOutlined />}>Check for deleted e-mails</Button>
                                {/* <Button type="danger" style={{marginRight: 8, paddingBottom: 4}}  icon={<WarningOutlined />}>Completely disregard configuration, nuke all results and trigger a full rescan!</Button> */}
                            </>
                        }
                    >
                        {rescanConfigForm}
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{marginBottom: 8}}>
                    <Card
                        title="Manage data viewing rights"
                        bordered={false}
                        style={{ width: "100%"}}
                        loading={loading}
                    >
                        {viewDataConsentForm}
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}