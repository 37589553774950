// index.js
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Login from "./Login";
// import { ThemeSwitcherProvider } from "react-css-theme-switcher";

// const themes = {
//     dark: './dark-theme.css',
//     light: './light-theme.css',
// };

ReactDOM.render(
    <>
        {window.o365_config.USE_DEVELOPMENT_BUILD ? <App /> : <Login />}
    </>,
document.getElementById("root")
);
