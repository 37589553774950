import React, {useState} from 'react';
import { Button, Card, Radio, Spin } from 'antd';

import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import PieChartOutlined from '@ant-design/icons/PieChartOutlined';

import AreaDetailsPie from './AreaDetailsPie';
import AreaDetailsBar from './AreaDetailsBar';
import { UndoOutlined } from '@ant-design/icons';

function propsAreEqual(prevProps, nextProps) {
    return prevProps.style === nextProps.style
        && prevProps.bodyStyle === nextProps.bodyStyle
        && prevProps.cardTitle === nextProps.cardTitle
        && prevProps.loadingData === nextProps.loadingData
        && prevProps.loading === nextProps.loading;
}

const AreaCard = React.memo(function AreaCard(props) {

    const [areaChartChoice, setAreaChartChoice] = useState('pie')

    return (
        <Spin spinning={props.loadingData} size="large" tip="Loading overview data..." >
        <Card bodyStyle={props.bodyStyle} hoverable bordered={false} 
            title={props.cardTitle}
            extra={<div style={{margin: -8}}>
                {!!props.showResetButton && 
                    <Button 
                        style={{marginRight: 8}} 
                        type="primary"
                        size="medium"
                        loading={props.loadingData} 
                        disabled={props.loadingData} 
                        onClick={() => props.clickGraph(null)} icon={<UndoOutlined />}
                    >
                        Reset + Reload
                    </Button>
                }
                {false && <Radio.Group size="medium" buttonStyle="solid" value={areaChartChoice} onChange={e => setAreaChartChoice(e.target.value)}>
                    <Radio.Button value='pie'><PieChartOutlined /></Radio.Button>
                    <Radio.Button value='bar'><BarChartOutlined /></Radio.Button>
                </Radio.Group>}
                </div>}
            style={props.style}
        >
            {(props.loading && !props.loadingData) &&
                <span style={{textAlign: "center", display: "block", marginTop:"16vh"}}>
                    <Spin size="large" tip="Loading data..." />
                </span>}
            {!props.loading && !!props.checkboxes && <div style={{zIndex: 999, position: "absolute", top: 70, left: 25}}>{props.checkboxes}</div>}
            {!props.loading && areaChartChoice === 'bar' && <AreaDetailsBar {...props} />}
            {!props.loading && areaChartChoice === 'pie' && <AreaDetailsPie {...props} />}
        </Card>
        </Spin>
    );
}, propsAreEqual);

export default AreaCard;

